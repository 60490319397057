<template>
  <b-input-group>
    <b-form-input
      id="fechaNacimiento"
      v-model="date"
      placeholder="dd/mm/aaaa"
      @input="formatDate"
      @keypress="isNumber"
      autocomplete="off"
      ></b-form-input>
  </b-input-group>
</template>

<script>
import {
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue'
import { getDatev2original } from '@/libs/utils/times'

export default {
  name: 'inputCalendar',
  components: {
    BFormInput,
    BInputGroup,
  },
  props: {
    real_date: {
      type: String
    }
  },
  data () {
    return {
      date: ''
    }
  },
  created () {
    if (this.real_date) this.date = getDatev2original(new Date(this.real_date))
  },
  methods: {
    formatDate() {
      if (this.date.length === 6 && this.date[this.date.length - 1] !== '/') {
        const cutted = this.date.slice(0, 5)
        const cutted_lat_digit = this.date[this.date.length - 1]
        this.date = `${cutted}/${cutted_lat_digit}`
      }
      if (this.date.length === 3 && this.date[this.date.length - 1] !== '/') {
        const cutted = this.date.slice(0, 2)
        const cutted_lat_digit = this.date[this.date.length - 1]
        this.date = `${cutted}/${cutted_lat_digit}`
      }
      if (this.date.length === 2 || this.date.length === 5) {
        this.date += '/';
      }
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if (((charCode > 31) && (charCode < 48 || charCode > 57)) || this.date.length > 9) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getDate() {
      const parts = this.date.split("/");
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
  }
}
</script>
<style lang="scss">

</style>
